*, *:before, *:after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
figure,
table,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
}

main {
  display: block;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

body * + * {
  margin-top: 32px;
  margin-bottom: 0;
}

summary + *,
script + *,
noscript + *,
input[type=hidden] + *,
.skip-links + *,
.notification-banner + *,
.global-header + *,
.main-content + * {
  margin-top: 0;
}

summary + * {
  margin-top: 16px;
}

input + label {
  margin-top: 0;
}

dt + dd,
li + li {
  margin-top: 4px;
}

h2 + *,
h3 + *,
h4 + *,
h5 + * {
  margin-top: 8px;
}

p + p {
  margin-top: 16px;
}

.c-meta-list + .list-unstyled {
  margin-top: 4px;
}

@media (min-width: 641px) {
  .u-clearfix > * {
    margin-top: 0;
  }
}

html {
  font-family: Arial, "Helvetica Neue", sans-serif;
  background-color: #dee0e2;
}

body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

a {
  color: #005ea5;
  text-decoration-skip-ink: auto;
  outline-color: transparent;
}
a:hover {
  color: #2b8cc4;
}
a:visited {
  color: #4c2c92;
}
a:active {
  color: #2b8cc4;
}

a,
input,
textarea,
button {
  outline: 3px solid transparent;
}
a:focus, a:focus + label::before,
input:focus,
input:focus + label::before,
textarea:focus,
textarea:focus + label::before,
button:focus,
button:focus + label::before {
  transition: box-shadow 0.1s, outline-color 0.1s 0.1s;
  box-shadow: 0 0 0 3px #ffbf47;
  outline-color: #ffbf47;
  text-decoration: none;
  outline-offset: 0;
}

input:focus + label::before {
  outline: none;
}

details {
  clear: both;
  display: block;
}
details summary {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-transform: none;
  color: #005ea5;
  cursor: pointer;
  display: inline-block;
  margin-bottom: em(5);
  position: relative;
}
@media (min-width: 641px) {
  details summary {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  details summary {
    font-size: 20px;
    line-height: 32px;
  }
}
details summary:hover {
  color: #2b8cc4;
}
details summary:focus {
  outline: 3px solid #ffbf47;
}
@-moz-document regexp(".*") {
  details summary:not([tabindex]) {
    display: list-item;
    display: revert;
  }
}

.details__summary {
  text-decoration: underline;
}
.details--inline .details__summary {
  text-decoration: none;
}

.details__arrow {
  font-style: normal;
  margin-right: 0.35em;
}
.details--inline .details__arrow {
  display: none;
}

.details__cta {
  color: #005ea5;
  cursor: pointer;
  text-decoration: underline;
}

.details--inline summary {
  color: #0b0c0c;
  cursor: default;
}
.details--inline summary::-webkit-details-marker {
  display: none;
}
.details--inline summary:hover {
  color: #0b0c0c;
}
.details--inline summary:focus {
  outline: none;
}
.details--inline summary:focus .details__cta {
  outline: 3px solid #ffbf47;
}

h1 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-transform: none;
}
@media (min-width: 641px) {
  h1 {
    font-size: 48px;
    line-height: 64px;
  }
}
@media (min-width: 925px) {
  h1 {
    font-size: 48px;
    line-height: 64px;
  }
}

h2 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-transform: none;
}
@media (min-width: 641px) {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

h3 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-transform: none;
}
@media (min-width: 641px) {
  h3 {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  h3 {
    font-size: 20px;
    line-height: 32px;
  }
}

h4 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
}
@media (min-width: 641px) {
  h4 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 925px) {
  h4 {
    font-size: 16px;
    line-height: 24px;
  }
}

h5 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
}
@media (min-width: 641px) {
  h5 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 925px) {
  h5 {
    font-size: 16px;
    line-height: 24px;
  }
}

ul {
  list-style-position: outside;
  padding-left: 1em;
}

ol {
  list-style-position: outside;
  padding-left: 1em;
}
ol li {
  padding-left: 0.2em;
}

dl dt {
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
table caption {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-transform: none;
  margin-bottom: 16px;
  text-align: left;
}
@media (min-width: 641px) {
  table caption {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  table caption {
    font-size: 24px;
    line-height: 32px;
  }
}
table th,
table td {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  border-bottom: 1px solid #bfc1c3;
  padding: 8px;
  vertical-align: top;
}
@media (min-width: 641px) {
  table th,
  table td {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 925px) {
  table th,
  table td {
    font-size: 16px;
    line-height: 24px;
  }
}
table th {
  font-weight: 700;
  text-align: left;
}
table th[align=right] {
  text-align: right;
}

.table__cell--plain {
  border-bottom: 0;
}

.list-unstyled,
.list-inline {
  list-style: none;
  padding-left: 0;
}

.list-inline li {
  margin-right: 1em;
  display: inline-block;
}

.list-lined li {
  border-bottom: 1px solid #bfc1c3;
  padding: 16px 16px 16px 0;
}
.list-lined li:first-child {
  border-top: 1px solid #bfc1c3;
}

.list-disc {
  list-style: disc outside;
  padding-left: 1em;
}

.js-enabled .js-ConditionalSubfield {
  display: none;
}
.js-enabled .js-ConditionalSubfield.is-active {
  display: block;
}

.global-header {
  background-color: #0b0c0c;
  color: #fff;
}

.global-header__inner {
  margin: 0 16px;
  max-width: 960px;
  padding: 10px 0;
}
.global-header__inner:after {
  clear: both;
  content: "";
  display: table;
}
@media (min-width: 925px) {
  .global-header__inner {
    margin: 0 32px;
  }
}
@media (min-width: 1024px) {
  .global-header__inner {
    margin: 0 auto;
  }
}

@media (min-width: 641px) {
  .global-header__site-title {
    float: left;
  }
}
.global-header__site-title .phase-badge {
  margin-top: 6px;
}

.global-header__crest {
  background: url("../../../images/dit-crest__white.png") 6px 0 no-repeat transparent;
  background-size: contain;
  border-left: 2px solid #fff;
  display: inline-block;
  height: 30px;
  margin-right: 8px;
  width: 36px;
  vertical-align: text-bottom;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 20/10), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .global-header__crest {
    background-image: url("../../../images/dit-crest__white@2x.png");
    background-size: 28px 30px;
  }
}

.global-header__link {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  padding: 0;
  position: relative;
  margin: 0 12px 0 0;
}
.global-header__link:hover, .global-header__link:visited, .global-header__link:focus {
  color: inherit;
}

.global-header__logo {
  border-bottom: 1px solid transparent;
  margin-left: -4px;
}

.global-header__link:hover .global-header__logo {
  border-color: #fff;
}

.global-header__site-name {
  display: block;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.global-header__service-name {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-transform: none;
  color: inherit;
  line-height: 0.9;
}
@media (min-width: 641px) {
  .global-header__service-name {
    font-size: 32px;
    line-height: 48px;
  }
}
@media (min-width: 925px) {
  .global-header__service-name {
    font-size: 36px;
    line-height: 48px;
  }
}
@media (min-width: 641px) {
  .global-header__service-name {
    line-height: 0.9;
    font-size: 30px;
  }
}

.global-footer {
  background-color: #dee0e2;
  border-top: 1px solid #bfc1c3;
  color: #6f777b;
  font-size: 0.9em;
}
.global-footer a {
  color: inherit;
}
.global-footer a:focus {
  color: #0b0c0c;
}

.global-footer__inner {
  margin: 0 16px;
  max-width: 960px;
  padding-top: 64px;
  padding-bottom: 64px;
}
@media (min-width: 925px) {
  .global-footer__inner {
    margin: 0 32px;
  }
}
@media (min-width: 1024px) {
  .global-footer__inner {
    margin: 0 auto;
  }
}

@media (min-width: 641px) {
  .global-footer__links,
  .global-footer__copyright {
    display: inline-block;
    margin-right: -4px;
  }
}

@media (min-width: 641px) {
  .global-footer__links {
    width: 75%;
  }
}

.global-footer__open-government-licence {
  margin-top: 1em;
}
@media (min-width: 641px) {
  .global-footer__open-government-licence {
    padding-left: 50px;
  }
}

.global-nav {
  padding: 12px 0;
  border-bottom: 1px solid #bfc1c3;
}
.global-nav:after {
  clear: both;
  content: "";
  display: table;
}

.global-nav__navigation {
  float: left;
}

.global-nav__account {
  float: right;
  color: #6f777b;
}

.global-nav__item {
  float: left;
  margin-left: 10px;
}
.global-nav__item:not(:first-child) {
  padding-left: 10px;
  border-left: 1px solid #6f777b;
}

.global-nav__link {
  display: inline-block;
}

.main-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
  min-height: 60vh;
  flex-grow: 1;
}

.main-content__inner {
  margin: 0 16px;
  max-width: 960px;
  padding-top: 32px;
  padding-bottom: 64px;
}
@media (min-width: 925px) {
  .main-content__inner {
    margin: 0 32px;
  }
}
@media (min-width: 1024px) {
  .main-content__inner {
    margin: 0 auto;
  }
}

.notification-banner {
  background-color: #d5e8f3;
}

.notification-banner__inner {
  margin: 0 16px;
  max-width: 960px;
  padding: 16px 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 925px) {
  .notification-banner__inner {
    margin: 0 32px;
  }
}
@media (min-width: 1024px) {
  .notification-banner__inner {
    margin: 0 auto;
  }
}

.phase-banner {
  width: 100%;
  display: table;
  font-size: 0.85em;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #bfc1c3;
}

span.phase-banner__message {
  display: inline-block;
}

.skip-links {
  background-color: #d5e8f3;
}

.skip-links__inner {
  margin: 0 16px;
  max-width: 960px;
}
@media (min-width: 925px) {
  .skip-links__inner {
    margin: 0 32px;
  }
}
@media (min-width: 1024px) {
  .skip-links__inner {
    margin: 0 auto;
  }
}

.skip-links__link {
  color: #fff;
  display: inline-block;
  left: -999em;
  padding: 8px;
  position: absolute;
}
.skip-links__link:focus {
  margin: 3px 0;
  color: #0b0c0c;
  outline: 0;
  position: static;
}

.example__header {
  padding-top: 4px;
  padding-bottom: 4px;
}

.example__title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  display: inline-block;
}

.example__preview {
  background-color: #fff;
  border: 2px solid #bfc1c3;
  padding: 16px;
}

@media (min-width: 1024px) {
  .example--wide .example__preview {
    margin-left: -32px;
    margin-right: -32px;
  }
}

.button {
  background-color: #00823b;
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  padding: 0.526315em 0.789473em 0.263157em;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  outline: 1px solid transparent;
  outline-offset: -1px;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 2px 0 rgb(0, 53.5, 24.2807692308);
  -moz-box-shadow: 0 2px 0 rgb(0, 53.5, 24.2807692308);
  box-shadow: 0 2px 0 rgb(0, 53.5, 24.2807692308);
  font-size: 1em;
  line-height: 1.25;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff;
  padding-bottom: 0.526315em;
}
.button:visited {
  background-color: #00823b;
}
.button:hover, .button:focus {
  background-color: rgb(0, 104.5, 47.4269230769);
}
.button:active {
  top: 2px;
  -webkit-box-shadow: 0 0 0 #00823b;
  -moz-box-shadow: 0 0 0 #00823b;
  box-shadow: 0 0 0 #00823b;
}
.button.disabled, .button[disabled=disabled], .button[disabled] {
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.button.disabled:hover, .button[disabled=disabled]:hover, .button[disabled]:hover {
  cursor: default;
  background-color: #00823b;
}
.button.disabled:active, .button[disabled=disabled]:active, .button[disabled]:active {
  top: 0;
  -webkit-box-shadow: 0 2px 0 rgb(0, 53.5, 24.2807692308);
  -moz-box-shadow: 0 2px 0 rgb(0, 53.5, 24.2807692308);
  box-shadow: 0 2px 0 rgb(0, 53.5, 24.2807692308);
}
.button:link, .button:link:focus, .button:hover, .button:focus, .button:visited {
  color: #fff;
}
.button:before {
  content: "";
  height: 110%;
  width: 100%;
  display: block;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.button:active:before {
  top: -10%;
  height: 120%;
}
@media (max-width: 640px) {
  .button {
    width: 100%;
    text-align: center;
  }
}

.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button:focus {
  outline: 3px solid #ffbf47;
}

.button[disabled=disabled]:focus {
  outline: none;
}

.button--secondary {
  background-color: #bfc1c3;
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  padding: 0.526315em 0.789473em 0.263157em;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  outline: 1px solid transparent;
  outline-offset: -1px;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 2px 0 rgb(151.5161290323, 154.75, 157.9838709677);
  -moz-box-shadow: 0 2px 0 rgb(151.5161290323, 154.75, 157.9838709677);
  box-shadow: 0 2px 0 rgb(151.5161290323, 154.75, 157.9838709677);
  font-size: 1em;
  line-height: 1.25;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #0b0c0c;
  padding-bottom: 0.526315em;
}
.button--secondary:visited {
  background-color: #bfc1c3;
}
.button--secondary:hover, .button--secondary:focus {
  background-color: rgb(177.8387096774, 180.25, 182.6612903226);
}
.button--secondary:active {
  top: 2px;
  -webkit-box-shadow: 0 0 0 #bfc1c3;
  -moz-box-shadow: 0 0 0 #bfc1c3;
  box-shadow: 0 0 0 #bfc1c3;
}
.button--secondary.disabled, .button--secondary[disabled=disabled], .button--secondary[disabled] {
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.button--secondary.disabled:hover, .button--secondary[disabled=disabled]:hover, .button--secondary[disabled]:hover {
  cursor: default;
  background-color: #bfc1c3;
}
.button--secondary.disabled:active, .button--secondary[disabled=disabled]:active, .button--secondary[disabled]:active {
  top: 0;
  -webkit-box-shadow: 0 2px 0 rgb(151.5161290323, 154.75, 157.9838709677);
  -moz-box-shadow: 0 2px 0 rgb(151.5161290323, 154.75, 157.9838709677);
  box-shadow: 0 2px 0 rgb(151.5161290323, 154.75, 157.9838709677);
}
.button--secondary:link, .button--secondary:link:focus, .button--secondary:hover, .button--secondary:focus, .button--secondary:visited {
  color: #0b0c0c;
}
.button--secondary:before {
  content: "";
  height: 110%;
  width: 100%;
  display: block;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.button--secondary:active:before {
  top: -10%;
  height: 120%;
}

.button--destructive {
  background-color: #b10e1e;
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  padding: 0.526315em 0.789473em 0.263157em;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  outline: 1px solid transparent;
  outline-offset: -1px;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 2px 0 rgb(106.1073298429, 8.3926701571, 17.9842931937);
  -moz-box-shadow: 0 2px 0 rgb(106.1073298429, 8.3926701571, 17.9842931937);
  box-shadow: 0 2px 0 rgb(106.1073298429, 8.3926701571, 17.9842931937);
  font-size: 1em;
  line-height: 1.25;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff;
  padding-bottom: 0.526315em;
}
.button--destructive:visited {
  background-color: #b10e1e;
}
.button--destructive:hover, .button--destructive:focus {
  background-color: rgb(153.3691099476, 12.1308900524, 25.9947643979);
}
.button--destructive:active {
  top: 2px;
  -webkit-box-shadow: 0 0 0 #b10e1e;
  -moz-box-shadow: 0 0 0 #b10e1e;
  box-shadow: 0 0 0 #b10e1e;
}
.button--destructive.disabled, .button--destructive[disabled=disabled], .button--destructive[disabled] {
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.button--destructive.disabled:hover, .button--destructive[disabled=disabled]:hover, .button--destructive[disabled]:hover {
  cursor: default;
  background-color: #b10e1e;
}
.button--destructive.disabled:active, .button--destructive[disabled=disabled]:active, .button--destructive[disabled]:active {
  top: 0;
  -webkit-box-shadow: 0 2px 0 rgb(106.1073298429, 8.3926701571, 17.9842931937);
  -moz-box-shadow: 0 2px 0 rgb(106.1073298429, 8.3926701571, 17.9842931937);
  box-shadow: 0 2px 0 rgb(106.1073298429, 8.3926701571, 17.9842931937);
}
.button--destructive:link, .button--destructive:link:focus, .button--destructive:hover, .button--destructive:focus, .button--destructive:visited {
  color: #fff;
}
.button--destructive:before {
  content: "";
  height: 110%;
  width: 100%;
  display: block;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.button--destructive:active:before {
  top: -10%;
  height: 120%;
}

.button--link {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #005ea5;
  cursor: pointer;
  padding: 0.4em 0;
  text-align: left;
  text-decoration: underline;
}
.button--link:hover, .button--link:active, .button--link:focus {
  background: transparent;
  color: #005ea5;
  top: 0;
}
.button--link:hover {
  color: #2b8cc4;
}

.button--compact {
  padding: 0;
}

.confirmation-banner {
  background: #28a197;
  color: #fff;
  max-width: 32em;
  padding: 32px;
  text-align: center;
}

.confirmation-banner__heading {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-transform: none;
}
@media (min-width: 641px) {
  .confirmation-banner__heading {
    font-size: 48px;
    line-height: 64px;
  }
}
@media (min-width: 925px) {
  .confirmation-banner__heading {
    font-size: 48px;
    line-height: 64px;
  }
}

.confirmation-banner__reference {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-transform: none;
  display: block;
}
@media (min-width: 641px) {
  .confirmation-banner__reference {
    font-size: 32px;
    line-height: 48px;
  }
}
@media (min-width: 925px) {
  .confirmation-banner__reference {
    font-size: 36px;
    line-height: 48px;
  }
}

.local-header__back {
  margin-top: -16px;
}

.local-header__heading {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-transform: none;
}
@media (min-width: 641px) {
  .local-header__heading {
    font-size: 48px;
    line-height: 64px;
  }
}
@media (min-width: 925px) {
  .local-header__heading {
    font-size: 48px;
    line-height: 64px;
  }
}
.local-header__heading + .local-header__subheading {
  margin-top: 0;
}

.local-header__subheading {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-transform: none;
  color: #6f777b;
}
@media (min-width: 641px) {
  .local-header__subheading {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  .local-header__subheading {
    font-size: 20px;
    line-height: 32px;
  }
}

.c-message-list:focus {
  outline: none;
}

.c-message {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-transform: none;
  border: 5px solid;
  padding: 16px;
  position: relative;
  background-color: #fff;
}
@media (min-width: 641px) {
  .c-message {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  .c-message {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (min-width: 641px) {
  .js-Messages .c-message {
    padding-right: 90px;
  }
}
.c-message span + span {
  margin-top: 0;
}

.c-message__close {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
  display: table;
  line-height: 32px;
  text-decoration: none;
}
@media (min-width: 641px) {
  .c-message__close {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 925px) {
  .c-message__close {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 641px) {
  .c-message__close {
    position: absolute;
    line-height: 32px;
    top: 16px;
    right: 16px;
  }
}
.c-message__close:hover {
  text-decoration: underline;
}

.c-message--info {
  border-color: #005ea5;
  color: #005ea5;
}

.c-message--success {
  border-color: #00823b;
  color: #00823b;
}

.c-message--warning {
  border-color: rgb(187.6285714286, 69.7714285714, 10.3714285714);
  color: rgb(187.6285714286, 69.7714285714, 10.3714285714);
}

.c-message--error {
  border-color: #b10e1e;
  color: #b10e1e;
}

.c-message--muted {
  border-width: 0 0 0 5px;
  border-color: #bfc1c3;
  background: #f8f8f8;
  font-size: inherit;
  font-weight: inherit;
}
.c-message--muted *:not(button):not(strong) {
  font-weight: inherit;
}

.c-meta-list__item + .c-meta-list__item {
  margin-top: 4px;
}

.c-meta-list__item-label {
  color: #6f777b;
  word-break: break-all;
}

.c-meta-list__item-value {
  color: #0b0c0c;
  word-break: break-all;
}
.c-meta-list__item-value:link, .c-meta-list__item-value:visited {
  color: inherit;
}
.c-meta-list__item-value:hover {
  text-decoration: none;
}
.c-meta-list__item-value.is-selected {
  font-weight: 600;
}

.c-meta-list--inline {
  display: inline-block;
  margin-top: -8px;
}
.c-meta-list--inline .c-meta-list__item-label {
  display: inline-block;
}
.c-meta-list--inline .c-meta-list__item {
  display: inline-block;
  margin-right: 32px;
  margin-top: 8px;
}
.c-meta-list--inline .c-meta-list__item:last-child {
  margin-right: 0;
}

.c-meta-list--condensed.c-meta-list--inline .c-meta-list__item {
  margin-right: 4px;
}
.c-meta-list--condensed.c-meta-list--inline .c-meta-list__item:last-child {
  margin-right: 0;
}

.c-meta-list__item--stacked {
  display: block;
}
.c-meta-list__item--stacked + .c-meta-list__item--stacked {
  margin-top: 8px;
}
.c-meta-list__item--stacked .c-meta-list__item-label {
  display: block;
}

.c-meta-list--split {
  margin-top: -4px;
  width: 100%;
}
.c-meta-list--split:after {
  clear: both;
  content: "";
  display: table;
}
.c-meta-list--split .c-meta-list__item {
  display: block;
  margin-top: 4px;
}
@media (min-width: 641px) {
  .c-meta-list--split .c-meta-list__item:nth-child(odd) {
    float: left;
    clear: both;
    max-width: 50%;
  }
  .c-meta-list--split .c-meta-list__item:nth-child(even) {
    float: right;
    text-align: right;
    max-width: 50%;
  }
}

.c-error-summary__heading,
.c-error-summary__list,
.c-error-summary__summary {
  margin: 0;
}

.c-error-summary {
  border: 5px solid #b10e1e;
  padding: 16px;
  max-width: 40em;
  margin-bottom: 32px;
}
.c-error-summary:focus {
  outline: 3px solid #ffbf47;
}
.c-error-summary * + * {
  margin-top: 16px;
}

.c-error-summary__heading {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-transform: none;
}
@media (min-width: 641px) {
  .c-error-summary__heading {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  .c-error-summary__heading {
    font-size: 24px;
    line-height: 32px;
  }
}

.c-error-summary__summary:last-child {
  margin-bottom: 0;
}

.c-error-summary__list {
  font-weight: 600;
  list-style: square;
  padding-left: 1em;
}

.c-error-summary__field-name {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
  display: block;
}
@media (min-width: 641px) {
  .c-error-summary__field-name {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 925px) {
  .c-error-summary__field-name {
    font-size: 16px;
    line-height: 24px;
  }
}

.c-error-summary__error-text:link, .c-error-summary__error-text:visited {
  color: #b10e1e;
}

.c-error-summary__list-item + .c-error-summary__list-item {
  margin-top: 16px;
}

.c-form-group {
  border: 0;
  max-width: 40em;
}
[type=hidden] + .c-form-group, .c-form-fieldset__legend + .c-form-group, .c-form-fieldset__hint + .c-form-group {
  margin-top: 0;
}

.c-form-group__inner {
  margin-top: 0;
  position: relative;
}

.c-form-group__label {
  display: table;
  line-height: 1.1;
  margin-bottom: 12px;
}
.c-form-group__label * + * {
  margin-top: 8px;
}

.c-form-group__label-text {
  font-weight: 600;
}

.c-form-group__hint {
  color: #6f777b;
  display: block;
}

.c-form-group__error-message {
  color: #b10e1e;
  font-weight: 600;
  display: block;
}

@media (min-width: 769px) {
  .c-form-group__action {
    position: absolute;
    left: 100%;
    margin-left: 16px;
    top: 0;
  }
}

.c-form-group.has-error {
  border-left: 5px solid #b10e1e;
  padding-left: 16px;
}

.c-form-group:target {
  animation: 1s targetHighlight ease;
}

.c-form-group--subfield {
  border-left: 5px solid #bfc1c3;
  margin-left: 16px;
}
.c-form-group--subfield, .c-form-group--subfield.has-error {
  padding-left: 28px;
}

@media (min-width: 641px) {
  .c-form-group--inline:not(fieldset) {
    vertical-align: top;
    display: inline-block;
  }
  .c-form-group--inline:not(fieldset) .c-form-group__label:not(legend) {
    display: inline-block;
    margin-right: 8px;
  }
  .c-form-group--inline:not(fieldset) .c-form-control {
    width: auto;
    display: inline-block;
    margin-top: 0;
  }
  .c-form-group--inline:not(fieldset) + .c-form-group--inline:not(fieldset):not(fieldset) {
    margin-left: 16px;
    margin-top: 0;
  }
}

.c-form-group--light .c-form-control,
.c-form-group--light .c-multiple-choice__input + .c-multiple-choice__label::before {
  border: 1px solid #6f777b;
}
.c-form-group--light .c-form-control:focus,
.c-form-group--light .c-multiple-choice__input:checked + .c-multiple-choice__label::before,
.c-form-group--light .c-multiple-choice__input:focus + .c-multiple-choice__label::before {
  border-color: #0b0c0c;
}

.c-form-group--soft .c-form-group__label-text {
  font-weight: normal;
}

.c-form-group--small,
.c-form-group--smaller {
  font-size: 16px;
}

.c-form-group--columns .c-form-group__inner {
  column-count: 2;
}

@keyframes targetHighlight {
  0% {
    border-color: #0b0c0c;
  }
  50% {
    background-color: #fff2d3;
  }
  100% {
    border-color: #b10e1e;
  }
}
.c-multiple-choice {
  position: relative;
  clear: left;
  transform: translateZ(0);
}
.c-multiple-choice + .c-multiple-choice {
  margin-top: 8px;
}

.c-multiple-choice__input {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  opacity: 0;
}
.c-multiple-choice__input[type=checkbox] + .c-multiple-choice__label::after {
  border-width: 0 0 5px 5px;
  width: 22px;
  height: 13px;
}
.c-multiple-choice__input + .c-multiple-choice__label::before {
  height: 38px;
  width: 38px;
}
.c-multiple-choice__input + .c-multiple-choice__label::after {
  border: 10px solid #0b0c0c;
  left: 9px;
  top: 9px;
}
.c-multiple-choice__input[type=checkbox] + .c-multiple-choice__label::after {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}
.c-multiple-choice__input[type=radio] + .c-multiple-choice__label::before, .c-multiple-choice__input[type=radio] + .c-multiple-choice__label::after {
  border-radius: 100%;
}
.c-multiple-choice__input + .c-multiple-choice__label::before, .c-multiple-choice__input + .c-multiple-choice__label::after {
  content: "";
  position: absolute;
  margin: auto;
}
.c-multiple-choice__input + .c-multiple-choice__label::before {
  border: 2px solid #0b0c0c;
  background-color: #fff;
  top: 0;
  left: 0;
}
.c-multiple-choice__input + .c-multiple-choice__label::after {
  height: 0;
  width: 0;
  visibility: hidden;
}
.c-multiple-choice__input:checked + .c-multiple-choice__label::after {
  visibility: visible;
}
.c-multiple-choice__input:focus + .c-multiple-choice__label::before {
  box-shadow: 0 0 0 4px #ffbf47;
}

.c-multiple-choice__label {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  padding: 8px 8px 8px 48px;
  line-height: 1.2;
}

.c-multiple-choice__hint {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  display: block;
  margin-top: 5px;
  color: #6f777b;
}
@media (min-width: 641px) {
  .c-multiple-choice__hint {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 925px) {
  .c-multiple-choice__hint {
    font-size: 16px;
    line-height: 24px;
  }
}
.c-multiple-choice__hint a, .c-multiple-choice__hint a:active, .c-multiple-choice__hint a:link, .c-multiple-choice__hint a:hover, .c-multiple-choice__hint a:visited {
  color: #6f777b;
}

.c-multiple-choice--small .c-multiple-choice__input {
  width: 32px;
  height: 32px;
}
.c-multiple-choice--small .c-multiple-choice__input[type=checkbox] + .c-multiple-choice__label::after {
  border-width: 0 0 5px 5px;
  width: 19px;
  height: 11px;
}
.c-multiple-choice--small .c-multiple-choice__input + .c-multiple-choice__label::before {
  height: 32px;
  width: 32px;
}
.c-multiple-choice--small .c-multiple-choice__input + .c-multiple-choice__label::after {
  border: 8px solid #0b0c0c;
  left: 8px;
  top: 8px;
}
.c-multiple-choice--small .c-multiple-choice__label {
  padding-left: 40px;
  line-height: 16px;
}

.c-multiple-choice--smaller .c-multiple-choice__input {
  width: 28px;
  height: 28px;
}
.c-multiple-choice--smaller .c-multiple-choice__input[type=checkbox] + .c-multiple-choice__label::after {
  border-width: 0 0 5px 5px;
  width: 16px;
  height: 9px;
}
.c-multiple-choice--smaller .c-multiple-choice__input + .c-multiple-choice__label::before {
  height: 28px;
  width: 28px;
}
.c-multiple-choice--smaller .c-multiple-choice__input + .c-multiple-choice__label::after {
  border: 7px solid #0b0c0c;
  left: 7px;
  top: 7px;
}
.c-multiple-choice--smaller .c-multiple-choice__label {
  padding-left: 35px;
  line-height: 16px;
}

.c-multiple-choice--inline {
  width: auto;
  display: inline-block;
  margin-top: 0;
  margin-right: 16px;
}

.u-visually-hidden {
  display: block;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.u-hidden {
  display: none !important;
}

.js-enabled .u-js-hidden {
  display: none;
}

.no-js .u-no-js-hidden {
  display: none !important;
}

@media print {
  .u-print-hide {
    display: none !important;
  }
}

.u-highlight {
  background-color: #fff2d3;
}

.u-clearfix:after {
  clear: both;
  content: "";
  display: table;
}

@media (min-width: 641px) {
  .u-float-left {
    float: left !important;
  }
}

@media (min-width: 641px) {
  .u-float-right {
    float: right !important;
  }
}

.u-max-half {
  max-width: 50%;
}

.u-block {
  display: block;
}

.u-loading::before {
  animation: growRight 3s;
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  border-top: 2px solid #2b8cc4;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.u-icon-print {
  background: url("../../images/icon--print.png") no-repeat 0 50%;
  border: 0;
  color: #005ea5;
  cursor: pointer;
  font-size: inherit;
  padding: 0 0 0 28px;
  text-decoration: underline;
}
.u-icon-print:hover {
  color: #2b8cc4;
}
.u-icon-print:active {
  color: #2b8cc4;
}
.u-icon-print:visited {
  color: #4c2c92;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 20/10), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .u-icon-print {
    background-image: url("../../images/icon--print@2x.png");
    background-size: 16px 18px;
  }
}

@keyframes growRight {
  0% {
    right: 100%;
  }
  20% {
    right: 60%;
  }
  100% {
    right: 0;
  }
}
.l-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 960px;
}
@media (min-width: 960px) {
  .l-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.l-reading-width {
  max-width: 32em;
}

@media (min-width: 641px) {
  .column-two-quarters > h1:first-child,
  .column-two-quarters > h2:first-child,
  .column-three-quarters > h1:first-child,
  .column-three-quarters > h2:first-child {
    margin-top: 0;
  }
}

.l-markdown h1 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-transform: none;
}
@media (min-width: 641px) {
  .l-markdown h1 {
    font-size: 32px;
    line-height: 48px;
  }
}
@media (min-width: 925px) {
  .l-markdown h1 {
    font-size: 36px;
    line-height: 48px;
  }
}
.l-markdown h2 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-transform: none;
}
@media (min-width: 641px) {
  .l-markdown h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  .l-markdown h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
.l-markdown h3 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-transform: none;
}
@media (min-width: 641px) {
  .l-markdown h3 {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  .l-markdown h3 {
    font-size: 20px;
    line-height: 32px;
  }
}
.l-markdown h4,
.l-markdown h5 {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
}
@media (min-width: 641px) {
  .l-markdown h4,
  .l-markdown h5 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 925px) {
  .l-markdown h4,
  .l-markdown h5 {
    font-size: 16px;
    line-height: 24px;
  }
}
.l-markdown * {
  margin: 0;
}
.l-markdown * + h1,
.l-markdown * + h2,
.l-markdown * + h3,
.l-markdown * + h4,
.l-markdown * + h5 {
  margin-top: 32px;
}
.l-markdown * + * {
  margin-top: 16px;
}
.l-markdown ul,
.l-markdown ol {
  padding-left: 1em;
}
.l-markdown ul {
  list-style-type: disc;
}
.l-markdown ol {
  list-style-type: decimal;
  padding-left: 1.5em;
}
.l-markdown ol li {
  padding-left: 0;
}

body {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-transform: none;
}
@media (min-width: 641px) {
  body {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  body {
    font-size: 20px;
    line-height: 32px;
  }
}

.font-large {
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-transform: none;
}
@media (min-width: 641px) {
  .font-large {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (min-width: 925px) {
  .font-large {
    font-size: 24px;
    line-height: 32px;
  }
}